import styled from '@emotion/styled';

import * as colors from '../../../styles/modules/colors.module.scss';
import * as utilities from '../../../styles/modules/utilities.module.scss';
import rhythm from '../../../utils/rhythm';

const Styles = styled.section({
  '.toggle': {
    position: 'relative',
    borderBottom: `1px solid ${colors.borderColor}`,

    '&:hover': {
      cursor: 'pointer',
    },
  },

  '.indicator': {
    position: 'absolute',
    bottom: `-${rhythm(1 / 2)}`,
    left: 0,
    right: 0,
    pointerEvents: 'none',

    svg: {
      transform: 'rotate(0deg)',
      transition: utilities.transition,
    },

    '.less': {
      svg: {
        transform: 'rotate(135deg)',
        transition: utilities.transition,
      },
    },
  },
});

export default Styles;
