import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Accordion, Badge } from 'react-bootstrap';

import Styles from './escapeDescription.styles';

const EscapeDescription = ({ description }) => {
  const [accordionOpen, toggleAccordionOpen] = useState(false);

  return (
    <Styles>
      {description.length > 960 ? (
        <div className="toggle">
          <Accordion>
            <Accordion.Toggle
              as={'div'}
              eventKey="0"
              onClick={() => toggleAccordionOpen(!accordionOpen)}
            >
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: description.substr(
                      0,
                      description.indexOf('<br />')
                    ),
                  }}
                  className="d-inline mr-2"
                />
                <Badge
                  pill
                  variant="light"
                  className={accordionOpen && 'd-none'}
                >
                  Read More
                </Badge>
              </>
              <Accordion.Collapse eventKey="0">
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: description.substr(
                        description.indexOf('<br />') + 6,
                        description.length
                      ),
                    }}
                    className="d-inline mr-2"
                  />
                  <Badge
                    pill
                    variant="light"
                    className={!accordionOpen && 'd-none'}
                  >
                    Read Less
                  </Badge>
                </>
              </Accordion.Collapse>
            </Accordion.Toggle>
          </Accordion>
        </div>
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )}
    </Styles>
  );
};

export default EscapeDescription;

EscapeDescription.propTypes = {
  description: PropTypes.string.isRequired,
};
