import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { GatsbyImage } from 'gatsby-plugin-image';
import GoogleMapReact from 'google-map-react';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Badge } from 'react-bootstrap';

import EscapeAmenities from '../../src/components/escape/escapeAmenities/escapeAmenities';
import Layout from '../../src/components/layout/layout';
import ScrollTopButton from '../../src/components/scrollTopButton/scrollTopButton';
import Share from '../../src/components/share/share';
import * as colors from '../../src/styles/modules/colors.module.scss';
import rhythm from '../../src/utils/rhythm';
import EscapeDescription from '../components/escape/escapeDescription/escapeDescription';
import EscapeHeader from '../components/escape/escapeHeader/escapeHeader';
import EscapeCard from '../components/escapeCard/escapeCard';
import Gallery from '../components/gallery/gallery';
import NewsletterBanner from '../components/newsletterBanner/newsletterBanner';
import SeoPress from '../components/seoPress/seoPress';
import { ReactComponent as Square } from '../images/aspect-square.svg';
import Styles from './escape.styles';

// import Book from '../components/escape/escapeBooking/book';

export const EscapeMapMarker = () => (
  <div
    style={{
      fontSize: rhythm(3 / 2),
      marginTop: `-${rhythm(3 / 4)}`,
      color: colors.secondary,
    }}
  >
    <FontAwesomeIcon icon={['fas', 'map-marker']} />
  </div>
);

const EscapeTemplate = ({ data }) => {
  const escape = data.allWpEscape.nodes[0];
  const provider = escape.author.node;
  const escapeContent = escape.escape;

  // Book modal controls
  // const [showBook, setShowBook] = useState(false);
  // const handleCloseBook = () => setShowBook(false);
  // const handleShowBook = () => setShowBook(true);

  // Share modal controls
  const [showShare, setShowShare] = useState(false);
  const handleCloseShare = () => setShowShare(false);
  const handleShowShare = () => setShowShare(true);

  // Gallery modal controls
  const [showGallery, setShowGallery] = useState(false);
  const handleCloseGallery = () => setShowGallery(false);
  const handleShowGallery = () => setShowGallery(true);
  const [galleryScroll, setGalleryScroll] = useState(0);
  const selectGalleryImage = (selectedIndex, e) => {
    setGalleryScroll(selectedIndex);
  };

  // GA4 booking link click event
  const googleTracking = () =>
    window.gtag('event', 'book', {
      provider: provider.name,
      provider_id: provider.slug,
      escape: escape.title,
      escape_id: escape.slug,
    });

  // Segment booking link click event
  const segmentTracking = () =>
    window.analytics.track('Booking Link Clicked', {
      provider: provider.name,
      provider_id: provider.slug,
      escape: escape.title,
      escape_id: escape.slug,
    });

  // Track booking link clicks
  const trackBook = () =>
    process.env.NODE_ENV === 'production'
      ? typeof window !== 'undefined' &&
        function () {
          googleTracking();
          segmentTracking();
        }
      : function () {
          console.log('Booking link clicked for ' + escape.title);
          segmentTracking();
        };

  // Log page view in Segment
  useEffect(() => {
    window.analytics.page('Escape Page Viewed', {
      title: escape.seo?.title || escape.title,
      url: `${data.site.siteMetadata.siteUrl}/${provider.slug}/${escape.slug}`,
      path: `${provider.slug}/${escape.slug}`,
      provider: provider.name,
      provider_id: provider.slug,
      escape: escape.title,
      escape_id: escape.slug,
    });
  });

  return (
    <Layout>
      <SeoPress
        seo={escape.seo}
        title={escape.seo?.title || escape.title}
        uri={`${provider.slug}/${escape.slug}/`}
        featuredImage={escapeContent.images[0].localFile.publicURL}
      />

      <Styles>
        <EscapeHeader
          title={escape.title}
          providerName={provider.name}
          streetAddress={escapeContent.location.destination.streetAddress}
          image={escapeContent.images[0]}
        />

        <h1>{provider.id}</h1>

        <Container>
          <Row>
            <Col xs={{ span: 12 }} lg={{ span: 8 }} className="pt-3 pt-md-5">
              {/* Rental Capacity*/}
              {escapeContent.details.capacity.bedrooms && (
                <section className="capacity pb-4">
                  <Row className="text-center">
                    <Col>
                      <h3 className="mb-0">
                        {escapeContent.details.capacity.bedrooms}
                      </h3>
                      <small>Bedrooms</small>
                    </Col>
                    <Col>
                      <h3 className="mb-0">
                        {escapeContent.details.capacity.bathrooms}
                      </h3>
                      <small>Bathrooms</small>
                    </Col>
                    <Col>
                      <h3 className="mb-0">
                        {escapeContent.details.capacity.maximumOccupancy}
                      </h3>
                      <small>Occupancy</small>
                    </Col>
                  </Row>
                </section>
              )}

              {/* Tour Dates */}
              {escapeContent.details.dates && (
                <section className="dates">
                  <h2 className="h4">Upcoming Dates</h2>
                  <ul className="mb-0 pl-3">
                    {escapeContent.details.dates.map((date, index) => (
                      <li key={index}>
                        {date.start} to {date.end}
                      </li>
                    ))}
                  </ul>
                </section>
              )}

              {/* Description */}
              <section
                className={
                  escapeContent.images.length > 1
                    ? 'pb-0 border-bottom-0 description'
                    : 'description'
                }
              >
                <EscapeDescription description={escapeContent.description} />
              </section>

              {/* Image Thumbnails */}
              {escapeContent.images.length > 1 && (
                <section className="thumbnails">
                  <Row className="no-gutters">
                    {escapeContent.images.slice(0, 8).map((image, index) => (
                      <Col
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          selectGalleryImage(index);
                          handleShowGallery();
                        }}
                        key={index}
                      >
                        <BackgroundImage
                          fluid={image.localFile.childImageSharp.fluid}
                        >
                          <Square
                            className="w-100"
                            style={{ height: 'auto', opacity: 0 }}
                            alt={image.altText ?? escape.title}
                          />
                        </BackgroundImage>
                      </Col>
                    ))}
                  </Row>
                </section>
              )}

              {/* Hotel & Rental Amenities */}
              {escapeContent.type === 'hotel' ||
              escapeContent.type === 'rental' ? (
                <section className="amenities pb-0 border-0">
                  <EscapeAmenities
                    amenities={
                      escapeContent.type === 'hotel'
                        ? escapeContent.details.hotelAmenities
                        : escapeContent.details.rentalAmenities
                    }
                  />
                </section>
              ) : (
                <></>
              )}

              {/* Room Types */}
              {escapeContent.details.roomTypes && (
                <section className="room-types pb-2">
                  <h2 className="h4">Rooms</h2>

                  {escapeContent.details.roomTypes.map((room, index) => (
                    <Row className="mb-3" key={index}>
                      <Col xs={12} md={4} className="mb-2 mb-md-0">
                        <GatsbyImage
                          image={
                            room.image.localFile.childImageSharp.gatsbyImageData
                          }
                          alt={room.image.altText ?? room.title}
                          className="d-block w-100"
                          backgroundColor={colors.light}
                        />
                      </Col>
                      <Col xs={12} md={8}>
                        <h3 className="h5">{room.title}</h3>
                        <p className="small mb-0">{room.description}</p>
                      </Col>
                    </Row>
                  ))}
                </section>
              )}

              {/* Audiences */}
              {escapeContent.audiences && (
                <section className="audiences">
                  <h2 className="h4">This Escape Is For</h2>
                  <ul className="mb-0 pl-3 small">
                    {escapeContent.audiences.map((audience, index) => (
                      <li className="font-weight-normal" key={index}>
                        {audience.audience}
                      </li>
                    ))}
                  </ul>
                </section>
              )}

              {/* Highlights */}
              {escapeContent.highlights && (
                <section className="highlights">
                  <h2 className="h4">What Else To Know</h2>
                  <ul className="mb-0 pl-3 small">
                    {escapeContent.highlights.map((highlight, index) => (
                      <li className="font-weight-normal" key={index}>
                        {highlight.highlight}
                      </li>
                    ))}
                  </ul>
                </section>
              )}

              {/* Values */}
              {escapeContent.values.length > 1 && (
                <section className="values pb-0 border-bottom-0">
                  <h2 className="h4">
                    {provider.databaseId !== 1 ? (
                      <>
                        {provider.name}'
                        <span style={{ textTransform: 'none' }}>s</span> Values
                      </>
                    ) : (
                      <>Why We Love This Escape</>
                    )}
                  </h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: escapeContent.values }}
                  />
                </section>
              )}

              {/* Map */}
              <section className="map">
                <div style={{ height: '50vh', width: '100%' }}>
                  {/* Todo: Replace key with environment variable */}
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: 'AIzaSyB7LIuGFDmNuV4kr9vVen_iWbhwsWCfBsA',
                    }}
                    defaultCenter={{
                      lat:
                        escapeContent.location.address?.latitude ||
                        escapeContent.location.destination.latitude,
                      lng:
                        escapeContent.location.address?.longitude ||
                        escapeContent.location.destination.longitude,
                    }}
                    defaultZoom={10}
                  >
                    <EscapeMapMarker
                      lat={
                        escapeContent.location.address?.latitude ||
                        escapeContent.location.destination.latitude
                      }
                      lng={
                        escapeContent.location.address?.longitude ||
                        escapeContent.location.destination.longitude
                      }
                    />
                  </GoogleMapReact>
                </div>
              </section>
            </Col>

            <Col
              xs={{ span: 12, order: 'first' }}
              lg={{ span: 4, order: 'last' }}
              className="pt-3 pt-md-5"
            >
              <div className="sticky-top" style={{ top: rhythm(2) }}>
                {/* Pricing */}
                <div className="text-center mb-3">
                  <small>Starting At</small>
                  <br />
                  <h3 className="mb-0">
                    {escapeContent.type === 'hotel' ||
                    escapeContent.type === 'rental' ? (
                      <>
                        ${escapeContent.price.perNight}
                        <br className="d-block d-md-none" />
                        <span className="d-none d-md-inline">&nbsp;</span>
                        <small>Per Night</small>
                      </>
                    ) : (
                      <></>
                    )}

                    {escapeContent.type === 'tour' ||
                    escapeContent.type === 'experience' ? (
                      <>${escapeContent.price.startingAt}</>
                    ) : (
                      <></>
                    )}
                  </h3>
                </div>

                {/* Provider Link */}
                <OutboundLink
                  onClick={trackBook()}
                  href={`${escapeContent.contact.bookingUrl}?utm_source=mindful_escapes`}
                  target="_blank"
                  rel="noopener"
                  className="d-block w-100 btn btn-primary mb-3"
                >
                  <FontAwesomeIcon icon={['fal', 'compass']} />
                  &nbsp;Book Now
                </OutboundLink>

                {/* Book Button */}
                {/*<Button
                  variant="primary"
                  className="d-block w-100 btn btn-primary mb-3"
                  onClick={handleShowBook}
                >
                  <FontAwesomeIcon icon={['fal', 'compass']} />
                  &nbsp;Book Now
                </Button>*/}

                <Row className="justify-content-center">
                  {/* Gallery Button */}
                  {escapeContent.images.length > 1 && (
                    <Col xs={6} lg={12}>
                      <Button
                        variant="light"
                        className="d-block w-100 btn-blog-sidebar mb-3"
                        onClick={() => {
                          selectGalleryImage(null);
                          handleShowGallery();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={['fal', 'images']}
                          className="d-none d-lg-inline-block"
                        />
                        &nbsp;Gallery
                      </Button>
                    </Col>
                  )}

                  {/* Share Button */}
                  <Col xs={6} lg={12}>
                    <Button
                      variant="light"
                      className="d-block w-100 btn-blog-sidebar"
                      onClick={handleShowShare}
                    >
                      <FontAwesomeIcon
                        icon={['fal', 'share']}
                        className="d-none d-lg-inline-block"
                      />
                      &nbsp;Share
                    </Button>
                  </Col>
                </Row>

                <hr className="my-4" />

                <div className="taxonomies">
                  {/* Geographies */}
                  {escape.geographies.nodes?.length > 0 &&
                    escape.geographies.nodes.map((geography) => (
                      <Badge
                        pill
                        variant="light"
                        className="mr-1"
                        key={geography.id}
                      >
                        {geography.name}
                      </Badge>
                    ))}

                  {/* Categories */}
                  {escape.categories.nodes?.length > 0 &&
                    escape.categories.nodes.map((category) => (
                      <Badge
                        pill
                        variant="light"
                        className="mr-1"
                        key={category.id}
                      >
                        {category.name}
                      </Badge>
                    ))}

                  {/* Tags */}
                  {escape.tags.nodes?.length > 0 &&
                    escape.tags.nodes.map((tag) => (
                      <Badge pill variant="light" className="mr-1" key={tag.id}>
                        {tag.name}
                      </Badge>

                      // Todo: Implement after taxonomy pages are live
                      /*<Link
                      to={`/${tag.slug}/`}
                      className="mr-1"
                      key={tag.id}
                    >
                      <Badge pill variant="light">
                        {tag.name}
                      </Badge>
                    </Link>*/
                    ))}
                </div>

                <div className="provider d-none d-lg-block text-center">
                  <hr className="my-4" />
                  {/* Provider */}
                  {provider.databaseId !== 1 ? (
                    <>
                      {provider.userProviderProfile.logo ? (
                        <GatsbyImage
                          image={
                            provider.userProviderProfile.logo.localFile
                              .childImageSharp.gatsbyImageData
                          }
                          alt={provider.userProviderProfile.logo.altText}
                          className="d-inline-block bg-white border-light mb-2 rounded-circle"
                          style={{ borderStyle: 'solid', borderWidth: '1px' }}
                          backgroundColor={colors.light}
                        />
                      ) : (
                        <img
                          src={provider.avatar.url}
                          alt={provider.name}
                          className="d-inline-block bg-white mb-2 rounded-circle"
                        />
                      )}
                      <h5>{provider.name}</h5>
                    </>
                  ) : (
                    <>
                      <p>Interested in becoming a Mindful Escapes provider?</p>
                      <a
                        href="mailto:editor@mindfulescapes.com"
                        className="btn btn-sm btn-light"
                      >
                        Claim This Escape
                      </a>
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        {data.relatedEscapes.nodes.length > 0 ? (
          <>
            <Container>
              <hr className="my-5" />
            </Container>

            <section className="mb-0 border-0">
              <h2 className="text-center mb-4">More From {provider.name}</h2>
              <Container fluid={true}>
                <Row className="justify-content-center">
                  {data.relatedEscapes.nodes.map((escape, index) => (
                    <Col xs={12} lg={4} className="mb-4" key={index}>
                      <EscapeCard escape={escape} />
                    </Col>
                  ))}
                </Row>
              </Container>
            </section>
          </>
        ) : (
          <></>
        )}

        <section className="pt-4 pb-6">
          <NewsletterBanner />
        </section>
      </Styles>

      <ScrollTopButton showBelow={360} />

      {/* Book Modal */}
      {/*<Book
        show={showBook}
        close={handleCloseBook}
        escapeName={escape.title}
        escapeType={escapeContent.type}
        escapeDates={escapeContent.details.dates}
        escapeProviderName={provider.name}
      />*/}

      {/* Hidden Booking Form for Netlify Forms*/}
      {/*<div className="d-none">
        <HiddenBookingForm
          escapeName={escape.title}
          escapeType={escapeContent.type}
          escapeDates={escapeContent.details.dates}
          escapeProviderName={provider.name}
          submitText={`Book Now`}
          confirmationMessage={`Your inquiry has been sent.`}
        />
      </div>*/}

      {/* Gallery Modal */}
      {escapeContent.images.length > 1 && (
        <Gallery
          show={showGallery}
          close={handleCloseGallery}
          images={escapeContent.images}
          title={escape.title}
          scrollTo={galleryScroll}
        />
      )}

      {/* Share Modal */}
      <Share
        show={showShare}
        close={handleCloseShare}
        url={`${data.site.siteMetadata.siteUrl}/${escape.author.node.slug}/${escape.slug}/`}
        type="Escape"
        id={`${escape.author.node.slug}_${escape.slug}`}
      />
    </Layout>
  );
};

export default EscapeTemplate;

export const query = graphql`
  query ($slug: String!, $author: String!) {
    allWpEscape(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        slug
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphImage {
            localFile {
              publicURL
            }
          }
          twitterTitle
          twitterDescription
          twitterImage {
            localFile {
              publicURL
            }
          }
        }
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
        author {
          node {
            name
            email
            slug
            databaseId
            avatar {
              url
            }
            userProviderProfile {
              logo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 120
                      aspectRatio: 1
                    )
                  }
                }
              }
            }
          }
        }
        categories {
          nodes {
            id
            name
            slug
          }
        }
        geographies {
          nodes {
            id
            name
            slug
          }
        }
        tags {
          nodes {
            id
            name
            slug
          }
        }
        escapeTypes {
          nodes {
            id
            name
            slug
          }
        }
        escape {
          description
          type
          values
          price {
            perNight
            startingAt
          }
          contact {
            bookingUrl
          }
          audiences {
            audience
          }
          highlights {
            highlight
          }
          location {
            destination {
              latitude
              longitude
              streetAddress
            }
            address {
              latitude
              longitude
              streetAddress
            }
          }
          details {
            capacity {
              bedrooms
              bathrooms
              maximumOccupancy
            }
            roomTypes {
              title
              description
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 480)
                  }
                }
              }
            }
            dates {
              start
              end
            }
            hotelAmenities
            rentalAmenities
          }
          images {
            id
            altText
            caption
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                gatsbyImageData(layout: CONSTRAINED, width: 1920)
              }
            }
          }
        }
      }
    }
    relatedEscapes: allWpEscape(
      filter: {
        author: { node: { slug: { eq: $author } } }
        slug: { ne: $slug }
      }
      limit: 3
    ) {
      nodes {
        ...cardEscape
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
