import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import globals from '../../../../globals';
import * as colors from '../../../styles/modules/colors.module.scss';

const EscapeHeader = ({ title, providerName, streetAddress, image }) => {
  const data = useStaticQuery(graphql`
    query EscapeHeader {
      fallbackImage: file(relativePath: { eq: "backgrounds/bg-fallback.jpg" }) {
        ...imageFluidExtraLarge
      }
    }
  `);

  let backgroundFluidImageStack = [
    image?.localFile.childImageSharp.fluid || data.fallbackImage,
    globals.backgroundOverlay,
  ].reverse();

  return (
    <BackgroundImage Tag="header" fluid={backgroundFluidImageStack}>
      <div
        className="d-flex w-100 content"
        style={{ minHeight: globals.minHeaderHeight }}
      >
        <Container className="d-flex align-items-center pt-7 pb-6">
          <Row className="d-flex flex-column text-white">
            <Col xs={12} md={11}>
              <h1
                className="display-1 text-white mb-2"
                style={{ lineHeight: 1 }}
              >
                {title} by {providerName}
              </h1>
              <p className="lead font-weight-normal mb-0">{streetAddress}</p>
            </Col>
          </Row>
        </Container>
      </div>
    </BackgroundImage>
  );
};

export default EscapeHeader;

EscapeHeader.propTypes = {
  title: PropTypes.string.isRequired,
  providerName: PropTypes.string.isRequired,
  streetAddress: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};
