import styled from '@emotion/styled';

import * as utilities from '../../../styles/modules/utilities.module.scss';

const Styles = styled.section({
  '.toggle': {
    position: 'relative',

    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default Styles;
