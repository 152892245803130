import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Accordion, Badge, Col, Row } from 'react-bootstrap';

import Styles from './escapeAmenities.styles';

const EscapeAmenities = ({ amenities }) => {
  const [accordionOpen, toggleAccordionOpen] = useState(false);

  return (
    <Styles>
      {amenities.length > 6 ? (
        <div className="toggle">
          <Accordion>
            <Accordion.Toggle
              as="div"
              className="pb-4"
              eventKey="0"
              onClick={() => toggleAccordionOpen(!accordionOpen)}
            >
              <Row>
                {amenities.slice(0, 6).map((amenity, index) => (
                  <Col xs={6} key={index} className="small text-secondary">
                    {amenity
                      // insert a space before all caps
                      .replace(/([A-Z])/g, ' $1')
                      // uppercase the first character
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                  </Col>
                ))}
              </Row>
              <Accordion.Collapse eventKey="0">
                <Row>
                  {amenities.slice(6).map((amenity, index) => (
                    <Col xs={6} key={index} className="small text-secondary">
                      {amenity
                        // insert a space before all caps
                        .replace(/([A-Z])/g, ' $1')
                        // uppercase the first character
                        .replace(/^./, function (str) {
                          return str.toUpperCase();
                        })}
                    </Col>
                  ))}
                </Row>
              </Accordion.Collapse>
            </Accordion.Toggle>

            <div className="d-block text-center indicator">
              <Badge pill variant="light" className={accordionOpen && 'less'}>
                <FontAwesomeIcon icon={['fal', 'plus']} />
              </Badge>
            </div>
          </Accordion>
        </div>
      ) : (
        <Row>
          {amenities.map((amenity, index) => (
            <Col xs={6} key={index}>
              {amenity
                // insert a space before all caps
                .replace(/([A-Z])/g, ' $1')
                // uppercase the first character
                .replace(/^./, function (str) {
                  return str.toUpperCase();
                })}
            </Col>
          ))}
        </Row>
      )}
    </Styles>
  );
};

export default EscapeAmenities;

EscapeAmenities.propTypes = {
  amenities: PropTypes.array.isRequired,
};
