import styled from '@emotion/styled';

import * as colors from '../styles/modules/colors.module.scss';
import rhythm from '../utils/rhythm';

const Styles = styled.main({
  section: {
    marginBottom: rhythm(1.5),
    paddingBottom: rhythm(1.5),
    borderBottom: `1px solid ${colors.light}`,

    '&:last-of-type': {
      marginBottom: 0,
      paddingBottom: 0,
      borderBottom: 'none',
    },
  },
});

export default Styles;
